<template>
	<div
		v-intro="
			'<h2>Retrouve tes anciennes sessions</h2>Tu peux rejouer tes anciennes sessions pour t\'entrainer davantage et avoir un meilleur score.'
		"
		v-intro-tooltip-class="'custom-tooltip-introjs'"
	>
		<ListChapterSketelon v-if="!selectingParcours"></ListChapterSketelon>
		<ListChapters v-else :parcours="selectingParcours" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ListChapterSketelon from '../../../skeletons/ListChapterSketelon';
import ListChapters from '../chapter/ListChapters';

export default {
	name: 'ParcoursDetail',
	components: {
		ListChapterSketelon,
		ListChapters,
	},
	data() {
		return {
			selectingParcours: null,
		};
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
	},
	created() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.selectingParcours = currentParcoursItem.parcours_id;
		},
	},
};
</script>

<style lang="scss" scoped></style>
